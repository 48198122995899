import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { useActivity } from "../../contexts/ActivityContext";
import CategorySkeletons from "../../components/CategorySkeletons";
import LazyImage from "../../components/lazyImage";

const CategoryListUser = ({
  categories,
  selectedCategory,
  handleCategoryClick,
}) => {
  const theme = useTheme();
  const { selectedLanguage } = useAuth();
  const { trackCategory } = useActivity();

  const isLoading = categories.length === 0;

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: { xs: "nowrap", md: "wrap" },
        alignContent: "start",
        gap: 2,
        padding: 0,
        my: 2,
        width: "100%",
        height: { xs: "60%", md: "70%" },
        overflowY: "auto",
        overflowX: { xs: "auto", md: "hidden" },
        "&::-webkit-scrollbar": {
          height: "6px",
          width: "6px",
          display: "none",
          borderRadius: "20px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme.palette.secondary.main,
          borderRadius: "20px",
          height: "6px",
          width: "6px",
          display: "none",
        },
        "&::-webkit-scrollbar-track": {
          borderRadius: "20px",
          backgroundColor: theme.palette.primary.main,
          height: "6px",
          width: "6px",
          display: "none",
        },
      }}
    >
      {isLoading ? (
        <CategorySkeletons />
      ) : (
        categories.map((category) => (
          <CategoryUser
            key={category.id}
            category={category}
            trackCategory={trackCategory}
            handleCategoryClick={handleCategoryClick}
            theme={theme}
            selectedCategory={selectedCategory}
            selectedLanguage={selectedLanguage}
          />
        ))
      )}
    </Box>
  );
};

export default CategoryListUser;

const CategoryUser = ({
  category,
  trackCategory,
  handleCategoryClick,
  theme,
  selectedCategory,
  selectedLanguage,
}) => {
  const translatedName =
    category.languages?.find(
      (lang) => lang.language_id === selectedLanguage.language_id
    )?.name || category.name;
  
  // if(category){console.log(category.image)}
  return (
    <Box
      key={category.id}
      sx={{
        position: "relative",
        textAlign: "center",
        width: { xs: "70%", md: "152px" },
        height: { xs: "100%", md: "107px" },
        boxSizing: "border-box",
        border:
          selectedCategory.id === category.id
            ? `3px solid ${theme.palette.secondary.main}`
            : "none",
        overflow: "hidden",
        backgroundColor: "#ccc7bf",
        cursor: "pointer",
        flex: "0 0 auto",
      }}
      onClick={() => {
        handleCategoryClick(category.id);
        trackCategory(category.id);
      }}
    >
      {/* <LazyImage
        src={category.image}
        alt={translatedName}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      /> */}
      <img
        src={category.image}
        alt={translatedName}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: -1,
          display: "flex",
          justifyContent: "start",
          px: "5px",
          alignItems: "center",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          backdropFilter: "blur(4px)",
          color: "#fff",
          height: { xs: "50px", md: "31px" },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { xs: "16px", md: "12px" },
            textAlign: "left",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {translatedName}
        </Typography>
      </Box>
    </Box>
  );
};
